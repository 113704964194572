@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.reassurance-payment {
	text-align: center;

	&__payment {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			margin-left: 10px;

			&:first-of-type {
				margin-left: 0;
			}
		}
	}

	@include gridle_state(md) {
		text-align: left;

		&__payment {
			justify-content: left;
		}
	}
}
