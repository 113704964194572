@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.popover {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 75px;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 20px;
	padding: 10px 5px;
	border: 1px solid $color-error;
	background: $color-error;
	color: white;
	border-radius: 3px;
	text-align: center;
	letter-spacing: 0.1rem;
	font-size: 1.3rem;
	text-transform: none;
	display: none;

	a {
		font-size: 1.3rem;
		color: white;
	}

	&--opened {
		display: inline-block;
		animation: alphaBounce 800ms ease-out;
	}

	&:before {
		border-top: 10px solid $color-error;
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;
		bottom: -10px;
		margin-left: -7px;
		content: "";
		display: block;
		left: 50%;
		position: absolute;
	}

	&__content {
		display: flex;
	}

	&__text {
		flex: 1 1 0%;
	}

	&__close {
		width: 10px;
		margin-right: 5px;
		margin-left: 15px;

		.icon.icon--cross {
			height: 10px;
			width: 10px;
			cursor: pointer;
		}
	}
}
