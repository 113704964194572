@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.separator {
	margin-left: auto;
	margin-right: auto;
	height: 1px;
	width: 100px;

	&--left {
		margin-left: 0;
		background-color: $grey-dark;
		width: 100px;

		&-white {
			margin-left: 0;
			background-color: white;
			width: 100px;
		}
	}

	&--middle {
		background-color: $grey-medium;
	}

	&--middle-dark {
		background-color: $grey-dark;
	}

	&--full {
		width: 100%;
		background-color: $grey-medium;

		&-white {
			width: 100%;
			background-color: white;
		}
	}

	&--full-dark {
		width: 100%;
		background-color: $grey-dark;
	}

	&--wide-margin {
		margin-top: 25px;
		margin-bottom: 25px;
	}

	&--narrow-margin {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}
