@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$menu-account-menu-item-color: $black !default;
$hamburger-layer-color: $black !default;
$menu-account-submenu-item-color: $black !default;

.account-menu {
	&__button {
		width: 100%;
	}

	&__global-message {
		display: flex;
		align-items: center;
		padding-left: 25px;
		color: $hamburger-layer-color;

		&-label {
			font-size: 1.8rem;
			margin-left: 18px;
		}

		.icon-account {
			&__icon,
			&__wpr {
				position: relative;
				overflow: visible;
				height: 22px;
				width: 22px;

				.dot-notification {
					height: 6px;
					width: 6px;
				}
			}
		}
	}

	&__item {
		cursor: pointer;
		padding-top: 20px;
		padding-bottom: 20px;
		margin-left: 65px;
		margin-right: 21px;
		border-top: 1px solid $grey-medium;

		.dot-notification {
			position: absolute;
			top: 1px;
			right: -5px;
			width: 7px;
			height: 7px;
		}

		&:first-of-type {
			border-top: none;
		}

		&:last-of-type {
			padding-bottom: 20px;
		}

		.relative-link {
			justify-content: flex-start;
			color: $menu-account-submenu-item-color;

			&__text {
				position: relative;
			}
		}
	}
}
